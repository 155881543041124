import { Component, OnInit } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../service/proxy.service';
import { EntityQuery, SimpleFunctionType } from '@dohu/ibis-entity';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html'
})

export class DashboardComponent extends iBisBaseComponent implements OnInit {
	dsParty: any;
	maxDate: Date;
	dsAge: any[] = [];
	dsSex: any[] = [];
	dsDoctor: any[] = [];
	dataFilters: { endDate: Date; partyId: string; };

	constructor(public proxy: ProxyService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	ngOnInit() {
		// this.maxDate = new Date(new Date().setDate(new Date().getMonth() + 1));
		// this.dsParty = this.proxy.ds.getParty();
		// this.getPartyDoctors().then((data: any) => {
		// 	if (data && data.data && data.data.length) {
		// 		this.dsDoctor = data.data;
		// 	}
		// });
		// this.onFormValueChanged(null);
		// this.dataFilters = { endDate: new Date(), partyId: this.proxy.ds.authDetails.party.id };
	}

	onFormValueChanged = (ev: any) => {
		// let obj: any = { endDate: new Date(), partyId: this.proxy.ds.authDetails.party.id }
		// if (ev && ev.component) {
		// 	if (ev.component.option('formData')) {
		// 		obj = ev.component.option('formData');
		// 	}
		// }
		// const fd = new Date(obj.endDate);
		// fd.setDate(1);
		// const td = new Date(obj.endDate);
		// td.setDate(-1);
		// this.initSex(fd, td, obj.partyId, obj.userId);
		// this.initAge(fd, td, obj.partyId, obj.userId);
	}

	// getPartyDoctors(partyId?: string) {
	// 	return new Promise((resolve: any) => {
	// 		const q = new EntityQuery('PartyUser').eq('partyId', partyId ?? this.authDetails.party.id);
	// 		q.fields.push('userId');
	// 		q.distinct = true;
	// 		this.entity.store(q, false, 'id').load().then((dt: any) => {
	// 			const ds = dt.map(d => d.userId);
	// 			if (!ds || !ds.length) { return; }
	// 			const dlink = new EntityQuery('UserLoginRole').eq('name', 'doctor').link('groupId', 'rolegroupid', new EntityQuery('ApplicationConfig')
	// 				.linkEq('id', 'configId', 'Application', 'id', this.auth.companyId));
	// 			const qlink = new EntityQuery('ApplicationRole').link('roleId', 'id', dlink);

	// 			const q = new EntityQuery('UserLogin').in('id', ds).link('id', 'userId', qlink);
	// 			q.distinct = true;
	// 			this.entity.store(q, false, 'id', environment.saasServer).load().then((dts: any) => {
	// 				resolve(dts);
	// 			}, err => this.lg.showError(err))
	// 		});
	// 	});
	// }

	initSex(fd: Date, td: Date, partyId: string, userId?: string) {
		const q = new EntityQuery('SexDistributionView').eq('partyId', partyId)
			.lte('fd', this.proxy.entity.toDateFilter(fd))
			.gte('fd', this.proxy.entity.toDateFilter(td));
		if (userId) {
			q.eq('userId', userId);
		}
		this.proxy.entity.function(q, <any>[{ field: 'm', ft: SimpleFunctionType.Sum },
		{ field: 'f', ft: SimpleFunctionType.Sum }]).then(data => {
			if (data) {
				this.dsSex = [];
				this.dsSex.push({ gender: 'Barbati', no: data[0].f1 });
				this.dsSex.push({ gender: 'Femei', no: data[0].f2 });
			}
		})
	}

	initAge(fd: Date, td: Date, partyId: string, userId?: string) {
		const q = new EntityQuery('AgeCategoryView').eq('partyId', partyId)
			.lte('fd', this.proxy.entity.toDateFilter(fd))
			.gte('fd', this.proxy.entity.toDateFilter(td));;
		q.fields.push('age');
		if (userId) {
			q.eq('userId', userId);
		}
		this.proxy.entity.function(q,
			<any>[{ field: 'apps', ft: SimpleFunctionType.Sum }]).then(data => {
				if (data) {
					this.dsAge = data;
				}
			})
	}
}
