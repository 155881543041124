import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ProxyService } from '../../service/proxy.service';
import { DomSanitizer } from '@angular/platform-browser';
import { iBisBaseComponent } from '@dohu/ibis-common';
import * as $ from 'jquery';
import notify from 'devextreme/ui/notify';
import { EntityQuery } from '@dohu/ibis-entity';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.css']
})
export class FilePreviewComponent extends iBisBaseComponent implements OnInit {

  previewFile: any;
  dsPatientFiles: any;
  @Input() patientId: string;
  constructor(private proxy: ProxyService, private sanitizer: DomSanitizer) {
    super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
  }

  ngOnInit(): void {
    const obj: any = document.body;
    if (obj.removeEventListener) {
      obj.removeEventListener('DOMMouseScroll', this.mouseWheel, false);
      obj.removeEventListener('mousewheel', this.mouseWheel, false);
    }
    if(this.patientId){
      this.getPatientFiles(this.patientId).then((res: any) => {
        this.dsPatientFiles = res;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getPatientFiles(changes.patientId.currentValue).then((res: any) => {
      this.dsPatientFiles = res;
    });
  }

  onFileClick(ev: any) {
    this.proxy.file.getLastObjectURL(ev.itemData.id).then((data) => {
      if(data){
        this.previewFile = this.sanitizer.bypassSecurityTrustResourceUrl(data);
        this.resizeIframeContent();
      }else{
        notify(this.L('FILE_NOT_FOUND'), 'error', 3000);
      }
    })
  }

  downloadFile(fileId: string){
    this.proxy.file.download(fileId);
  }

  private resizeIframeContent() {
    setTimeout(() => {
      const ifrm: any = document.getElementById('iframe') as HTMLIFrameElement;
      if (ifrm && ifrm.contentWindow && ifrm.contentWindow.document && ifrm.contentWindow.document.body) {
        $(ifrm.contentWindow.document.body).children().width('100%');
      }
      const factor = 0.05;
      let scale = 1;
      const html = $(ifrm.contentWindow.document).children()[0];
      html.style.width = '100vw';
      html.style.height = '100vh';
      html.style.overflow = 'hidden';
      const body: any = $(ifrm.contentWindow.document.body)[0];
      body.style.width = '100vw';
      body.style.height = '100vh';
      body.style.overflow = 'scroll';
      const content = $(ifrm.contentWindow.document.body).children()[0];
      content.style.transformOrigin = '0 0';
      // const style = document.createElement('style');
      // style.innerHTML = `body::-webkit-scrollbar {display: none;}`;
      // html.children[0].appendChild(style);

      // mouse move
      let previousX: any, previousY: any;
      body.addEventListener('mousedown', (ev: any) => {
        ev.preventDefault();
        previousX = ev.pageX;
        previousY = ev.pageY;
      });

      body.addEventListener('mousemove', (ev: any) => {
        ev.preventDefault();
        // only do this when the primary mouse button is pressed (event.buttons = 1)
        if (ev.buttons) {
          let dragX = 0;
          let dragY = 0;
          // skip the drag when the x position was not changed
          if (ev.pageX - previousX !== 0) {
            dragX = previousX - ev.pageX;
            previousX = ev.pageX;
          }
          // skip the drag when the y position was not changed
          if (ev.pageY - previousY !== 0) {
            dragY = previousY - ev.pageY;
            previousY = ev.pageY;
          }
          // scrollBy x and y
          if (dragX !== 0 || dragY !== 0) {
            body.scrollBy(dragX, dragY);
          }
        }
      });
      // zoom in - out

      body.addEventListener('wheel', (ev: any) => {
        if (ev.ctrlKey) {
          let delta = ev.delta || ev.wheelDelta;
          if (delta === undefined) {
            delta = ev.originalEvent.detail;
          }
          delta = Math.max(-1, Math.min(1, delta));
          const offset = { x: body.scrollLeft, y: body.scrollTop };
          const fileLocation = { x: ev.pageX + offset.x, y: ev.pageY + offset.y };
          const zoomPoint = { x: fileLocation.x / scale, y: fileLocation.y / scale };
          scale += delta * factor * scale;
          scale = Math.max(0.5, Math.min(4, scale));
          const newZoomPoint = { x: zoomPoint.x * scale, y: zoomPoint.y * scale };
          const scroll = { x: newZoomPoint.x - ev.pageX, y: newZoomPoint.y - ev.pageY };
          // content.style.transformOrigin = scale >= 1 ? '0 0' : 'center';
          content.style.transform = 'scale(' + scale + ',' + scale + ')';
          body.scrollTop = scroll.y;
          body.scrollLeft = scroll.x;
        }
      });
      // prevent scrolling
      content.addEventListener('DOMMouseScroll', this.mouseWheel, false);
      content.addEventListener('mousewheel', this.mouseWheel, false);
      const obj: any = document.body;
      if (obj.addEventListener) {
        obj.addEventListener('DOMMouseScroll', this.mouseWheel, false);
        obj.addEventListener('mousewheel', this.mouseWheel, false);
      }

    }, 100);
  }

  private mouseWheel(e: any) {
    // disabling
    // tslint:disable-next-line: deprecation
    e = e ? e : window.event;
    if (e.ctrlKey) {
      if (e.preventDefault) {
        e.preventDefault();
      } else { e.returnValue = false; }
      return false;
    }
  }

  private getPatientFiles(patientId: any){
    return new Promise((resolve) => {
			const appointments = new EntityQuery('Appointment').eq('patientId', patientId).addOrderByDesc(['startDate']);
			appointments.fields.push('id');
			this.proxy.entity.load(appointments).then((app: any) => {
				const appIds = app && app.length ? app.map(x => x.id) : [];
				appIds.push(this.proxy.auth.user.id);

				const q = new EntityQuery('FileInfo').in('entityId', appIds).addOrderByDesc(['uploadTime']);
				this.proxy.entity.store(q, false, 'id', this.proxy.environment.saasServer).load().then((res: any) => {
					resolve(res);
				}, err => this.lg.showError(err));
			}, err => this.lg.showError(err));
		});
  }
}
