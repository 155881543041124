import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { EntityQuery, EntityConditionGroup, iBisEntityService } from '@dohu/ibis-entity';
import { environment } from '../../environments/environment';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisLanguageService } from '@dohu/ibis-common';
import * as sd from '../../assets/static.json';
import { Subject } from 'rxjs/internal/Subject';
import { DomSanitizer } from '@angular/platform-browser';
import DataSource from 'devextreme/data/data_source';

@Injectable()
export class DataService {
	isLoginModalVisible: boolean;
	previousUrl: any;
	static: any;
	

	authDetails = { party: { id: '', name: '' }, role: { id: '', name: '' }, specialization: [{ id: '', name: '' }] };

	public logoSubject: Subject<any> = new Subject<any>();

	constructor(public entity: iBisEntityService, public auth: iBisAuthService, public router: Router, public http: HttpClient,
		public httpClient: HttpClient, public lg: iBisLanguageService, public sanitizer: DomSanitizer) {
		this.static = sd.default;
	}

	getAge(cnp: string) {
		if (typeof cnp == 'string') {
			const yy = [1, 2].indexOf(parseInt(cnp[0], 10)) > -1 ? '19' : '20';
			const bd = new Date(parseInt(yy + cnp.substring(1, 3), 10), parseInt(cnp.substring(3, 5), 10) - 1, parseInt(cnp.substring(5, 7)));
			return Math.round((new Date().getTime() - bd.getTime()) / 1000 / 60 / 60 / 24 / 365);
		}
	}

	/* enum values */
	public getEnum(key: string, list: { 'id': number; 'value': string }[]): { 'id': number; 'value': string }[] {
		const result = [];
		const values = this.static[key] ?? list;
		values.forEach((u: { id: number; value: string }) => {
			result.push({ id: u.id, value: this.lg.format(u.value) });
		});
		return result;
	}

	getWorkingDays(partyId: string, userId?: string) {
		const q = new EntityQuery('WorkingSchedule').eq('partyId', partyId).addOrderBy(['weekDay']);
		q.eq('userId', userId ? userId : null);
		return this.entity.store(q, false, 'id').load();
	}

	getClinicWorkingSchedule(partyId: string, weekDay: number) {
		const q = new EntityQuery('WorkingSchedule').eq('partyId', partyId).eq('userId', null).eq('weekDay', weekDay);
		return this.entity.single(q);
	}

	getFiles(entityId: string[]) {
		const q = new EntityQuery('FileInfo').in('entityId', entityId).addOrderBy(['uploadTime']);
		return this.entity.store(q, false, 'id', environment.saasServer).load();
	}
}
