import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DxSchedulerComponent } from 'devextreme-angular/ui/scheduler';
import notify from 'devextreme/ui/notify';
import { iBisViewComponent } from '@dohu/ibis-common';
import { confirm } from 'devextreme/ui/dialog';
import { Subscription } from 'rxjs';
import { SignalRService } from '../../../service/signalR.service';

import { ProxyService } from '../../../service/proxy.service';

@Component({
	selector: 'app-operation-room',
	templateUrl: './operation-room.component.html'
})
export class OperationRoomComponent extends iBisViewComponent implements OnInit, OnDestroy {

	dsClinicWorkingDays: any = [];

	@ViewChild('scheduler') scheduler: DxSchedulerComponent;

	timeout: any;
	showBeginConsultCondition: boolean;

	private notifySub: Subscription;

	constructor(public proxy: ProxyService, public signalR: SignalRService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);

	}

	ngOnInit() {
		this.notifySub = this.proxy.iBisNotify.updated.subscribe((ent) => {
			if (ent === 'Appointment') {
				this.proxy.calendar.refresh();
			}
		});
		this.showBeginConsultCondition = false;
	}


	initScheduler() {
		this.proxy.operationRoom.refresh();
		if (this.scheduler) {
			this.scheduler.instance.hideAppointmentTooltip();
		}
	}


	ngOnDestroy() {

		if (this.notifySub) {
			this.notifySub.unsubscribe();
		}
	}

	initClinicWorkingSchedule() {
		// this.loadWorkingDays().then((result: any) => {
		// 	this.dsClinicWorkingDays = result.data;
		// }, err => this.proxy.lg.showError(err));
	}

	onViewChangeEv(event: any){
		if(event.name === 'currentView'){
			this.proxy.calendar.currentView = this.viewMap[event.value.toLowerCase()] || event.value;
		}
	}

	private viewMap: { [key: string]: string } = {
		zi: 'day',
		luna: 'month'
	  };


	getAppointmentData(data: any) {
		const app = data.appointmentData;
		const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
		const selectedDate = new Date(new Date(app.startDate).setHours(0, 0, 0, 0));
		const notPastAppointment = currentDate <= selectedDate;

		this.showBeginConsultCondition = (app.ap_statusId === 2 || app.ap_statusId === 1) && notPastAppointment;
	}

	onAppointmentDblClick(event: any) {
		event.cancel = true;
		const data = event.appointmentData;

		let type = null;
		switch (data.ap_statusId) {
			case 1:
			case 2:
			case 3:
				if (this.proxy.calendar.isAssistent || this.proxy.calendar.isAdmin) {
					type = 2;
				}
				break;
			case 4:
				if (this.proxy.calendar.isDoctor || this.proxy.calendar.isAdmin) {
					type = 11;
				}
				break;
		}
		if (type) {
			this.scheduler.instance.repaint();
			this.appointmentStatus(data, type);
		}
	}


	deleteOperationAppointment(data: any, ev: any) {
		if (ev) {
			ev.event.preventDefault();
			ev.event.stopPropagation();
		}
		confirm('Ești sigur că dorești anularea programării ?', 'Confirm').then(val => {
			if (val) {
				this.scheduler.instance.hideAppointmentTooltip();
				this.proxy.entity.remove('Appointment', data.ap_id).then(() => {
					notify('Programare ștearsă cu succes', 'success', 3000);
				}, err => this.proxy.lg.showError(err));
			}
		});
	}

	onCellClick(event: any) {
		if (!this.timeout) {
			this.timeout = setTimeout(() => {
				this.timeout = null;
			}, 500);
		} else {
			let obj: any = {};
				obj.startDate = event.cellData.startDate;
				obj.files = [];
				if(event.cellData.groups){
					obj.operatingRoomId = event.cellData.groups.ap_operatingRoomId;
				}
				this.proxy.appointment.showPopup(obj);
		}
		event.cancel = true;
	}

	preventDefaultPopup(ev: any) {
		if (ev && ev.event) {
			// dx button
			ev.event.preventDefault();
			ev.event.stopPropagation();
		} else if (ev) {
			// eveniment de click
			ev.preventDefault();
			ev.stopPropagation();
		}
	}

	//TODO: TBD
	// sendPatientSms(data: any, ev: any) {
	// 	this.preventDefaultPopup(ev);
	// 	this.proxy.entity.execute('NotifyAppointment', { appointmentId: data.ap_id }).then(() => {
	// 		notify('Mesaj trimis cu succes.', 'success', 3000);
	// 		this.proxy.entity.save('Appointment', data.ap_id, { isSent: true }).then(() => {
	// 			this.scheduler.instance.hideAppointmentTooltip();
	// 		}, error => this.proxy.ds.lg.showError(error));
	// 	}, error => this.proxy.ds.lg.showError(error));
	// }

	appointmentStatus(appointment: any, status: number, ev?: any) {
		this.preventDefaultPopup(ev);
		switch (status) {
			// reprogrameaza
			case 2:
				this.proxy.appointment.showPopup(appointment.ap_id, true);
				break;
			// confirma consultatia
			case 3:
				this.proxy.entity.save('Appointment', appointment.ap_id, { statusId: 3 }).then((result: any) => {
					this.initScheduler();
				});
				break;

			case 4: // incepe consultatia
			case 11: // modifica consultatia
				this.proxy.consultation.showPopup(appointment.ap_id, true).then((result: any) => {
					this.initScheduler();
				})
				break;
			case 5: // finalizeaza consultatie
				this.proxy.consultation.finishConsultation(appointment.ap_id).then((result: any) => {
					this.initScheduler();
				});
				break;

			case 10: // sterge consultatie
				this.proxy.consultation.remove(appointment.ap_id).then(() => {
					this.initScheduler();
				});
				break;
		}
	}

	private loadWorkingDays(doctors = []) {
		// const q = new EntityQuery('WorkingSchedule').eq('partyId', this.authDetails.party.id).addOrderBy(['weekDay']);
		// if (doctors && doctors.length) {
		// 	// doctor schedule
		// 	q.in('userId', doctors);
		// } else {
		// 	// clinic schedule
		// 	q.eq('userId', null);
		// }
		// return this.entity.load(q);
	}
}


