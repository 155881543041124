import notify from 'devextreme/ui/notify';
import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisFileService, iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';

@Injectable()

export class EditAppointmentService extends iBisSecureEditService {

	public data: CustomStore;

	public appointmentsByDateAndDoctor: any[];
	public invalidTimes: string[] = [];

	public apptStartTimes = [];
	public apptEndTimes = [];

	// TODO: 
	// workingTime = the working times that the doctor has in that day
	// interval = the interval in minutes between appointments
	// private workingTime: Array<string> = ['08:00 - 12:00', '13:00 - 20:00'];
	private workingTime: Array<string> = ['08:00 - 20:00'];
	private timeInterval: number = 20;

	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService,
		public ds: DataService, public file: iBisFileService) {
		super(auth, entity, config, lg);
		this.validation = 'appValidation';
		this.title = 'APPOINTMENT';
		setTimeout(() => {
			this.load();
		}, 500);
	}

	protected load(): void {
		this.data = this.getData();
	}

	createDefault() {
		return { startDate: new Date(), filesToUpload: [], files: [] };
	}

	getById(id: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.entity.getById('Appointment', id).then((data: any) => {
				data.startTime = this.returnTime(new Date(data.startDate));
				data.endTime = this.returnTime(new Date(data.endDate));
				data.files = [];
				this.initFiles(data.id);
				resolve(data)
			})
		});
	}

	onRemove(id: string): Promise<void> {
		return new Promise((resolve) => { });
	}

	onSaveEv(): Promise<any> {
		const date = new Date(this.model.startDate);
		const st = this.model.startTime.split(':');
		const et = this.model.endTime.split(':');
		this.model.startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), st[0], st[1]);
		this.model.endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), et[0], et[1]);
		this.model.partyId = this.ds.authDetails.party.id;
		this.model.statusId = 1; // new
		// this.model.statusId = this.isSameTime(this.oldAppData);
		// TODO: Check if we can get from somewhere else partyId
		this.model.partyId = this.ds.authDetails.party.id;
		return new Promise((resolve) => {
			this.entity.save('Appointment', this.model.id, this.model).then((data) => {
				this.saveFiles(data.id, false);
				resolve(data);
				this.onDataChanged.next('saved');
			}, err => this.ds.lg.showError(err))
		});
	}

	reset(): void {
		this.model = this.createDefault();
	}

	uploadFile = () => {
		const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('multiple', '');
		input.setAttribute('accept', '.*');
		input.addEventListener('change', (e) => {
			for (let i = 0; i < input.files.length; i++) {
				this.model.files.push(input.files[i]);
				this.saveFiles(this.model.id, true);
			}
		}, false);
		input.click();
	}

	private initFiles(entityId: string) {
		this.file.getFileInfo(entityId).load().then((data: any) => {
			this.model.files = data;
		});
	}

	private saveFiles(entityId: string, refresh: boolean) {
		if (!entityId) return;
		for (let i = 0; i < this.model.files.length; i++) {
			this.file.uploadFile(entityId, null, this.model.files[i]).subscribe(() => {
				notify('Upload file ' + this.model.files[i].name + ' successfully finish', 'success', 3000);
				if ((this.model.files.length - 1) == i) {
					if (refresh) {
						this.initFiles(entityId);
					}
					this.model.files = [];
				}
			});
		}
	}

	private returnTime(time: any) {
		if (time) {
			const h = ('0' + new Date(time).getHours()).slice(-2);
			const m = ('0' + new Date(time).getMinutes()).slice(-2);
			return h + ':' + m;
		}
	}

	//isSameTime(oldData: any) {
		// if (Object.keys(oldData).length === 0) {
		// 	return 1;
		// } else if (!oldData.id) {
		// 	return 1;
		// } else if ((this.model.startDate.getTime() === new Date(oldData.startDate).getTime()) &&
		// 	(this.model.endDate.getTime() === new Date(oldData.endDate).getTime()) && oldData.statusId == 1) {
		// 	return 1;
		// } else {
		// 	return 2;
		// }
	//}

	//getWorkingSchedule(doctorId: string, date: Date) {
		// return new Promise((resolve: any) => {
		// 	const q = new EntityQuery('WorkingSchedule').eq('partyId', this.ds.authDetails.party.id).eq('weekDay', date.getDay());
		// 	const group = new EntityConditionGroup();
		// 	group.eq('userId', doctorId).eq('userId', null);
		// 	group.useOr = true;
		// 	q.conditionGroups.groups.push(group);
		// 	this.entity.load(q).then((ws: any) => {
		// 		if (ws && ws.data) {
		// 			const defaultTime = { startTime: '08:00', endTime: '20:00' };
		// 			switch (ws.data.length) {
		// 				// fara program
		// 				case 0:
		// 					resolve({ dws: defaultTime, cws: defaultTime });
		// 					break;
		// 				case 1:
		// 					// program doctor sau clinica
		// 					const isClinic = ws.data[0].userId === null;
		// 					const schedule = {
		// 						startTime: ws.data[0].startTime && ws.data[0].starTime !== '00:00' ? ws.data[0].startTime : '08:00',
		// 						endTime: ws.data[0].endTime && ws.data[0].endTime !== '00:00' ? ws.data[0].endTime : '20:00'
		// 					};
		// 					resolve({ dws: isClinic ? defaultTime : schedule, cws: isClinic ? schedule : defaultTime });
		// 					break;

		// 				case 2:
		// 					// program doctor si clinica
		// 					const dIndex = ws.data.findIndex((x: any) => x.userId !== null);
		// 					const cIndex = ws.data.findIndex((x: any) => x.userId === null);
		// 					const csTime = ws.data[cIndex].startTime && ws.data[cIndex].startTime !== '00:00' ? ws.data[cIndex].startTime : '08:00';
		// 					const ceTime = ws.data[cIndex].endTime && ws.data[cIndex].endTime !== '00:00' ? ws.data[cIndex].endTime : '20:00';
		// 					const dsTime = ws.data[dIndex].startTime && ws.data[dIndex].startTime !== '00:00' ? ws.data[dIndex].startTime : '08:00';
		// 					const deTime = ws.data[dIndex].endTime && ws.data[dIndex].endTime !== '00:00' ? ws.data[dIndex].endTime : '20:00';
		// 					resolve({
		// 						dws: { startTime: dsTime, endTime: deTime }, cws: { startTime: csTime, endTime: ceTime }
		// 					});
		// 			}
		// 		}
		// 	}, err => this.ds.lg.showError(err));
		// });
	//}

	// 	createTimeArrays(workingSchedule: any, appointment: any, date: any, appointmentData: any, currentApp?: any) {
	// 		let i = 0;
	// 		date = new Date(date);
	// 		const dsStartTimes = [];
	// 		let dsEndTimes = [];
	// 		const dsStartTimesDoctor = [];
	// 		let dsEndTimesDoctor = [];
	// 		let startTimeDate = this.returnOneTime(workingSchedule.cws.startTime, i);
	// 		let endTimeDate = this.returnOneTime(workingSchedule.cws.startTime, i);


	// 		while (startTimeDate < workingSchedule.cws.endTime) {
	// 			startTimeDate = this.returnOneTime(workingSchedule.cws.startTime, i);
	// 			endTimeDate = this.returnOneTime(workingSchedule.cws.startTime, i + 3);

	// 			if (appointment) {
	// 				let availableTime = new Date(date).setHours(parseInt(startTimeDate.split(':')[0], 10));
	// 				availableTime = new Date(availableTime).setMinutes(parseInt(startTimeDate.split(':')[1], 10));

	// 				let availableEndTime = new Date(date).setHours(parseInt(endTimeDate.split(':')[0], 10));
	// 				availableEndTime = new Date(availableEndTime).setMinutes(parseInt(endTimeDate.split(':')[1], 10));
	// 				// gasire timpi pentru appointments
	// 				const r = appointment.find((x: any) =>
	// 					(new Date(availableTime) <= new Date(x.startDate)) && (new Date(x.startDate) < new Date(availableEndTime)) ||
	// 					(new Date(availableTime) < new Date(x.endDate)) && (new Date(x.endDate) <= new Date(availableEndTime)) ||
	// 					(new Date(x.startDate) < new Date(availableTime)) && (new Date(availableEndTime) < new Date(x.endDate)));

	// 				const disableStartTime = (workingSchedule.dws.startTime > startTimeDate) || (workingSchedule.dws.endTime < startTimeDate);
	// 				const disableEndTime = (workingSchedule.dws.startTime > endTimeDate) || (workingSchedule.dws.endTime < endTimeDate);
	// 				if (!r) {
	// 					dsStartTimes.push(startTimeDate);
	// 					dsEndTimes.push(endTimeDate);
	// 					dsStartTimesDoctor.push({ text: startTimeDate, visible: !disableStartTime });
	// 					dsEndTimesDoctor.push({ text: endTimeDate, visible: !disableEndTime });
	// 				}
	// 				// cand deschide appointmentul actual
	// 				if (r && currentApp && r.ap_id === currentApp) {
	// 					dsStartTimes.push(startTimeDate);
	// 					dsEndTimes.push(endTimeDate);

	// 					dsStartTimesDoctor.push({ text: startTimeDate, visible: !disableStartTime });
	// 					dsEndTimesDoctor.push({ text: endTimeDate, visible: !disableEndTime });
	// 				}
	// 			}
	// 			i++;
	// 		}
	// 		// cand deschide appointment din scheduler, endtimes sa nu includa timpii inainte de starttime-ul selectat
	// 		if ((appointmentData.startTime)) {
	// 			dsEndTimes = dsEndTimes.filter(time => time > appointmentData.startTime);
	// 			dsEndTimesDoctor = dsEndTimesDoctor.filter(time => time > appointmentData.startTime);
	// 		}

	// 		dsStartTimes.splice(-3, 3);
	// 		dsEndTimes.splice(-3, 3);
	// 		dsStartTimesDoctor.splice(-3, 3);
	// 		dsEndTimesDoctor.splice(-3, 3);

	// 		return { start: dsStartTimes, end: dsEndTimes, startDoctor: dsStartTimesDoctor, endDoctor: dsEndTimesDoctor };
	// 	}

	// 	returnOneTime(time: any, increment: number) {
	// 		const st = time.split(':');
	// 		if (st.length < 2) {
	// 			console.log('Data type is not valid');
	// 			return '00:00';
	// 		}
	// 		let startTime = (st[0] * 60 + Number(st[1]));
	// 		startTime = startTime + increment * 10;
	// 		const hours = Math.floor(startTime / 60);
	// 		const minutes = (startTime % 60);

	// 		return ('0' + (hours % 24)).slice(-2) + ':' + ('0' + minutes).slice(-2);
	// 	}

	// 	addMinutesToDate(date: Date, minutes: number) {
	// 		return new Date(date.getTime() + minutes * 60000);
	// 	}

	// 	loadAppointments(ids?: string[], exceptOperating = true) {
	// 		const q = new EntityQuery('AppointmentView').addOrderByDesc(['ap.startDate']);
	// 		q.eq('ap.partyId', this.ds.authDetails.party.id);
	// 		if (ids && ids.length > 0) {
	// 			q.in('ap.userId', ids);
	// 		}
	// 		if (exceptOperating) {
	// 			q.eq('ap.operatingRoomId', null);
	// 		}
	// 		return this.entity.store(q, false, 'ap_id');
	// 	}

	// 	private getPartyDoctors() {
	// 		return new Promise((resolve: any) => {
	// 			const q = new EntityQuery('PartyUser').eq('partyId', this.ds.authDetails.party.id);
	// 			q.fields.push('userId');
	// 			q.distinct = true;
	// 			this.entity.load(q).then((dt: any) => {
	// 				const ds = dt.map(d => d.userId);
	// 				if (!ds || !ds.length) { return; }
	// 				const dlink = new EntityQuery('UserLoginRole').eq('name', 'doctor').link('groupId', 'rolegroupid', new EntityQuery('ApplicationConfig')
	// 					.linkEq('id', 'configId', 'Application', 'id', this.auth.companyId));
	// 				const qlink = new EntityQuery('ApplicationRole').link('roleId', 'id', dlink);

	// 				const q = new EntityQuery('UserLogin').in('id', ds).link('id', 'userId', qlink);
	// 				q.distinct = true;
	// 				this.entity.store(q, false, 'id', this.config.saasServer).load().then(dts => {
	// 					resolve(dts);
	// 				}, err => this.lg.showError(err))
	// 			});
	// 		});
	// 	}

	// 	private getWorkingDaysByDoctors(doctors = []) {
	// 		const q = new EntityQuery('WorkingSchedule').eq('partyId', this.ds.authDetails.party.id).addOrderBy(['weekDay']);
	// 		if (doctors && doctors.length) {
	// 			// doctor schedule
	// 			q.in('userId', doctors);
	// 		} else {
	// 			// clinic schedule
	// 			q.eq('userId', null);
	// 		}
	// 		return this.entity.store(q, false, 'id');
	// 	}

	// 	private getPatients(): CustomStore {
	// 		const q = new EntityQuery('Patient').addOrderBy(['lastName']);
	// 		this.entity.load(q).then((options: any) => {
	// 			const qq = q.clone();
	// 			qq.offset = options.skip || options.userData.skip || 0;
	// 			qq.limit = options.take || options.userData.take || 0;
	// 			if (options.filter && options.filter.length) {
	// 				if (options.filter[1] === 'or') {
	// 					const value = options.filter[0][2];
	// 					const splitValue = value.split(' ');
	// 					const group1 = new EntityConditionGroup();
	// 					const group2 = new EntityConditionGroup();
	// 					const group3 = new EntityConditionGroup();
	// 					const group4 = new EntityConditionGroup();
	// 					// case 1: firstName + lastName or reverse order
	// 					if (splitValue && splitValue.length > 1) {
	// 						group1.addCondition('firstName', 7, '%' + splitValue[0] + '%', false, false);
	// 						group1.addCondition('lastName', 7, '%' + splitValue[1] + '%', false, false);
	// 						group2.addCondition('firstName', 7, '%' + splitValue[1] + '%', false, false);
	// 						group2.addCondition('lastName', 7, '%' + splitValue[0] + '%', false, false);
	// 						qq.conditionGroups.groups.push(group1);
	// 						qq.conditionGroups.groups.push(group2);
	// 					}
	// 					// case 2: just firstName or lastName
	// 					group3.addCondition('firstName', 7, '%' + value + '%', false, false);
	// 					group3.addCondition('lastName', 7, '%' + value + '%', false, false);
	// 					group3.useOr = true;
	// 					qq.conditionGroups.groups.push(group3);

	// 					// case 3: when 2 firstName are present + lastName or reverse order
	// 					if (splitValue && splitValue.length === 3) {
	// 						const subGroupStart = new EntityConditionGroup();
	// 						subGroupStart.addCondition('firstName', 7, '%' + splitValue[0] + ' ' + splitValue[1] + '%', false, false);
	// 						subGroupStart.addCondition('lastName', 7, '%' + splitValue[2] + '%', false, false);
	// 						const subGroupEnd = new EntityConditionGroup();
	// 						subGroupEnd.addCondition('firstName', 7, '%' + splitValue[1] + ' ' + splitValue[2] + '%', false, false);
	// 						subGroupEnd.addCondition('lastName', 7, '%' + splitValue[0] + '%', false, false);
	// 						group4.groups.push(subGroupStart);
	// 						group4.groups.push(subGroupEnd);
	// 						group4.useOr = true;
	// 						qq.conditionGroups.groups.push(group4);
	// 					}
	// 				}
	// 				qq.conditionGroups.useOr = true;
	// 			}
	// 			return this.entity.execute('FindValues', qq).then((res: any) => {
	// 				for (const patient of res.Items) {
	// 					patient.fullName = patient.lastName + ' ' + patient.firstName;
	// 				}
	// 				return { data: res.Items ? res.Items : [], totalCount: res.Count };
	// 			});
	// 		});
	// 		return this.entity.store(q, false, 'id');
	// 	}

	public getHoursMinutes(date: Date): string {
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');

		return hours + ':' + minutes;
	}

	public refreshAppointmentsByDateAndDoctor() {
		const date = new Date(this.model.startDate);
		date.setHours(0, 0, 0, 0);
		const nextDate = new Date(date);
		nextDate.setDate(nextDate.getDate() + 1);
		const q = new EntityQuery('AppointmentView').eq('ap.userId', this.model.userId)
			.gte('startDate', date).lte('endDate', nextDate).addOrderBy(['startDate']);
		this.entity.load(q).then((result: any[]) => {
			this.appointmentsByDateAndDoctor = result;
			this.invalidTimes = [];
			result.forEach(appointment => {
				this.invalidTimes.push(this.getHoursMinutes(new Date(appointment.startDate)) + ' - ' + this.getHoursMinutes(new Date(appointment.endDate)));
			});
			this.getStartTimes();
		});
	}

	private getData(): CustomStore {
		const q = new EntityQuery('AppointmentView').addOrderByDesc(['startDate']);
		//TODO: GET PARTY_ID OF THE USER CONNECTED
		// q.eq('ap.partyId', this.ds.authDetails.party.id);
		return this.entity.store(q, false, 'ap_id');
	}

	// START TIMERS
	private getStartTimes() {

		this.apptStartTimes = this.generateAllowedTimes();
	}

	// Funcție pentru a genera orele permise pe baza intervalelor de lucru și invalidelor
	private generateAllowedTimes() {
		const allowedTimes = [];
		const invalidTimeRanges = this.invalidTimes.map(range => this.parseTimeRange(range));
		const workingTimeRanges = this.workingTime.map(range => this.parseTimeRange(range));

		// Parcurgem fiecare interval din workingTime și generăm orele disponibile
		workingTimeRanges.forEach(range => {
			for (let totalMinutes = range.start; totalMinutes <= range.end - this.timeInterval; totalMinutes += this.timeInterval) {
				const currentHour = Math.floor(totalMinutes / 60);
				const currentMinute = totalMinutes % 60;
				const formattedTime = ('0' + currentHour).slice(-2) + ':' + ('0' + currentMinute).slice(-2);

				// Verificăm dacă intervalul curent este invalid
				const isInvalid = invalidTimeRanges.some(invalidRange =>
					totalMinutes >= invalidRange.start && totalMinutes < invalidRange.end
				);

				if (!isInvalid) {
					allowedTimes.push({ displayValue: formattedTime, value: formattedTime });
				}
			}
		});

		return allowedTimes;
	}

	// END TIMERS
	public getEndTimes() {
		if (this.model.startTime) {
			// Generăm orele permise pentru endTime, începând de la startTime + 20 min
			this.apptEndTimes = this.generateAllowedTimesAfterStartTime();
		}
	}

	// Funcție pentru a genera orele permise după startTime
	private generateAllowedTimesAfterStartTime() {
		const allowedTimes = [];
		const invalidTimeRanges = this.invalidTimes.map(range => this.parseTimeRange(range));
		const workingTimeRanges = this.workingTime.map(range => this.parseTimeRange(range));

		// Calculăm limita de startTime pentru endTime (startTime + 20 min)
		const [startHour, startMinute] = this.model.startTime.split(':').map(Number);
		const startTimeInMinutes = startHour * 60 + startMinute + 20;

		// Parcurgem fiecare interval din workingTime și generăm orele disponibile după startTime
		workingTimeRanges.forEach(range => {
			for (let totalMinutes = Math.max(startTimeInMinutes, range.start); totalMinutes <= range.end - this.timeInterval; totalMinutes += this.timeInterval) {
				const currentHour = Math.floor(totalMinutes / 60);
				const currentMinute = totalMinutes % 60;
				const formattedTime = ('0' + currentHour).slice(-2) + ':' + ('0' + currentMinute).slice(-2);

				// Verificăm dacă intervalul curent este invalid
				const isInvalid = invalidTimeRanges.some(invalidRange =>
					totalMinutes >= invalidRange.start && totalMinutes < invalidRange.end
				);

				// Dacă următorul timp valid este în afara intervalului invalid, îl adăugăm
				if (!isInvalid || totalMinutes === invalidTimeRanges[0].start) {
					allowedTimes.push({ displayValue: formattedTime, value: formattedTime });
				}
			}
		});

		return allowedTimes;
	}


	// Funcție pentru a converti un interval de tip 'HH:mm - HH:mm' într-un obiect cu start și end în minute
	public parseTimeRange(range: string) {
		const [startTime, endTime] = range.split(' - ');
		const [startHour, startMinute] = startTime.split(':').map(Number);
		const [endHour, endMinute] = endTime.split(':').map(Number);

		return {
			start: startHour * 60 + startMinute,  // convertim la minute
			end: endHour * 60 + endMinute         // convertim la minute
		};
	}
}