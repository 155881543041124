import { Injectable } from '@angular/core';
import { DataService } from '../../../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { iBisFileService, iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import DataSource from 'devextreme/data/data_source';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';

@Injectable()

export class EditConsultationService extends iBisSecureEditService {


	index = 0;
	files = [];

	dsProceduresList: DataSource;
	dsDiagnostics: DataSource;

	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService,
		 public ds: DataService, public file: iBisFileService) {
		super(auth, entity, config, lg);
		this.title = this.L('CONSULTATION_MODIFY');
		this.validation = 'consultationValidation';

	}	
	
	protected load(): void {
		this.dsDiagnostics = new DataSource({ store: this.loadDiagnostic(), paginate: true, pageSize: 50 });
	}

	createDefault() {
		return {}
	}

	getById(id: any): Promise<any> {
		this.index = 0;
		this.dsProceduresList = new DataSource({ store: this.loadProcedures(), paginate: false });
		return new Promise<any>((resolve, reject) => {
			this.entity.getById('Appointment', id).then(result => {
				if (!result) {
					reject();
				} else {
					this.loadFiles(id);
					this.initTitle(result.patientId, result.description);
					this.refreshDiagnostics(result.id);
					this.initAppointmentProcedure(result.id);
					this.initAppointmentDiagnostic(result.id);
					result.quantity = {};
					result.statusId = 4;
					resolve(result);
				}
			})
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('Appointment', id);
	}

	onSaveEv(): Promise<any> {
		return new Promise((resolve, reject) => {
			if (this.model.procedure.length == 0) {
				notify(this.L('SELECT_PROCEDURES'), 'warning', 3000);
				reject();
			}
			this.entity.execute('SaveAppointment', this.model).then((result) => {
				resolve(result);
			})
		});
	}

	reset(): void {
		this.model = this.createDefault();
	}

	private loadFiles(id: string) {
		this.ds.getFiles([id]).then((response: any) => {
			this.files = response;
		});
	}

	finishConsultation(id: string) {
		return new Promise((resolve, reject) => {
			this.model.statusId = 5;
			this.entity.execute('SaveAppointment', this.model).then(data => {
				this.popup.hide();
				notify(this.L("FINISH_CONSULTATION"), 'success', 3000);
				resolve(data);
			});
		});
	}

	popupInit(ev: any) {
		super.popupInit(ev);
		ev.component.beginUpdate();
		const tbBtns = ev.component.option('toolbarItems');
		tbBtns.push({
			location: 'after',
			options: {
				text: this.L('FINISH'),
				icon: 'send',
				type: 'default',
				onClick: (e: any) => {
					if (this.model.procedure.length === 0) {
						notify(this.L('SELECT_PROCEDURES'), 'warning', 3000);
						return;
					}
					this.finishConsultation(this.model.id);
				}
			},
			toolbar: 'bottom',
			widget: 'dxButton'
		});
		tbBtns.push({
			location: 'before',
			options: {
				text: this.L('ADD_FILE'),
				icon: 'file',
				type: 'default',
				onClick: (e: any) => {
					this.file.upload(this.model.id).then(() => {
						this.loadFiles(this.model.id);
					})
				}
			},
			toolbar: 'bottom',
			widget: 'dxButton'
		});
		ev.component.option('toolbarItems', tbBtns);
		ev.component.option('height', '100vh');
		ev.component.option('weight', '100vh');
		ev.component.option('fullScreen', true);
		ev.component.endUpdate();
	}

	public refreshDiagnostics(apptId) {
		this.dsDiagnostics = new DataSource({ store: this.loadDiagnostic(apptId), paginate: true, pageSize: 50 });
	}

	private loadProcedures(): CustomStore {
		const q = new EntityQuery('Procedure').addOrderBy(['name']);
		if(this.ds.auth.isAuth ? (this.ds.authDetails.role.name !== 'admin') : false)
		{
			q.in('specializationId', this.ds.authDetails.specialization.map(s => s.id));
		}
		return this.entity.store(q, false, 'id');
	}

	private loadDiagnostic(apptId: string = null): CustomStore {
		const qry = new EntityQuery('Diagnostic').eq('isCustom', false).addOrderBy(['code']);
		if(apptId){
			const qryq =new EntityQuery('Diagnostic').eq('isCustom', true).addOrderBy(['code'])
			.linkEq('id','diagnosticId', 'AppointmentDiagnostic', 'appointmentId', apptId);
			qry.union(qryq);
		}
		return this.entity.store(qry, false, 'id');
	}

	private initTitle(patientId: string, description: string) {
		this.entity.getById('Patient', patientId).then(patient => {
			this.title = patient ? (patient.firstName + ' ' + patient.lastName + ' - ' + (patient.cnp ? this.ds.getAge(patient.cnp) + ' - ' : '') + description) : this.title;
		});
	}

	private initAppointmentDiagnostic(appointmentId: string): void {
		this.model.diagnostic = [];
		const qry = new EntityQuery('AppointmentDiagnostic').eq('appointmentId', appointmentId);
		qry.fields.push('diagnosticId');
		this.entity.store(qry, false, 'id').load().then((data: any) => {
			this.model.diagnostic = data.map(x => x.diagnosticId);
		});
	}
	private initAppointmentProcedure(appointmentId: string): void {
		const qry = new EntityQuery('AppointmentProcedure').eq('appointmentId', appointmentId);
		this.entity.store(qry, false, 'id').load().then((data: any) => {
			this.model.procedure = data.map(x => x.procedureId);
			const obj = {};
			for (const item of data) {
				obj[item.procedureId] = item.quantity;
			}
			this.model.quantity = obj;
		});
	}
}
