import { Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { SignalRService } from '../../../service/signalR.service';
import { DxSchedulerComponent } from 'devextreme-angular/ui/scheduler';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

@Component({
	selector: 'app-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent extends iBisViewComponent implements OnInit, OnDestroy {

	timeout: any;
	showBeginConsultCondition: boolean;
	@ViewChild('scheduler') scheduler: DxSchedulerComponent;

	private notifySub: Subscription;

	constructor(public proxy: ProxyService, public signalR: SignalRService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	ngOnInit() {

		this.notifySub = this.proxy.iBisNotify.updated.subscribe((ent) => {
			if (ent === 'Appointment') {
				this.proxy.calendar.refresh();
			}
		});
		this.showBeginConsultCondition = false;
	}

	ngOnDestroy() {
		if (this.notifySub) {
			this.notifySub.unsubscribe();
		}
	}

	initScheduler() {
		this.proxy.calendar.refresh();
		this.hideAppointmentTooltip();
	}

	private hideAppointmentTooltip() {
		if (this.scheduler) {
			this.scheduler.instance.hideAppointmentTooltip();
		}
	}

	dataCellTemplate = (cellData: any, index: any, container: any) => {
		// TODO: Check without setTimeout a way to start this method after this.proxy.calendar.doctodWorkingHours is loaded
		setTimeout(() => {
			if (cellData.groups) {
				const doctorId = cellData.groups?.ap_userId;
				const doctorWorkingHours = this.proxy.calendar.doctodWorkingHours[doctorId]; 
	
				if (!doctorWorkingHours) {
					if(!container.classList.contains('unavailable-cell')){
						container.classList.add('unavailable-cell');
					}
				} else {
					const cellStartHour = cellData.startDate.getHours();
					const cellStartMinute = cellData.startDate.getMinutes();
	
					const isWithinWorkingHours = doctorWorkingHours.some((interval: string) => {
						const [start, end] = interval.split(' - ');
	
						const [startHour, startMinute] = start.split(':').map(Number);
						const [endHour, endMinute] = end.split(':').map(Number);
	
						const cellTime = cellStartHour * 60 + cellStartMinute;
						const startTime = startHour * 60 + startMinute;
						const endTime = endHour * 60 + endMinute;
	
						return cellTime >= startTime && cellTime < endTime;
					});
					if(isWithinWorkingHours){
						container.classList.remove('unavailable-cell');
					} else if(!container.classList.contains('unavailable-cell')){
						container.classList.add('unavailable-cell');
					}
				}
	
			}
		}, 500);
	}

	editPatientData(data: any) {
		if (this.proxy.calendar.isAssistent || this.proxy.calendar.isAdmin) {
			this.scheduler.instance.hideAppointmentTooltip();
			this.proxy.patient.showPopup(data.ap_patientId, true).then((res: any) => {
				if (res) {
					this.initScheduler();
				}
			});
		}
	}

	onAppointmentDblClick(event: any) {
		event.cancel = true;
		const data = event.appointmentData;

		let type = null;
		switch (data.ap_statusId) {
			case 1:
			case 2:
			case 3:
				if (this.proxy.calendar.isAssistent || this.proxy.calendar.isAdmin) {
					type = 2;
				}
				break;
			case 4:
				if (this.proxy.calendar.isDoctor || this.proxy.calendar.isAdmin) {
					type = 11;
				}
				break;
		}
		if (type) {
			this.scheduler.instance.repaint();
			this.appointmentStatus(data, type);
		}
	}

	onCellClick(event: any) {
		if (!this.timeout) {
			this.timeout = setTimeout(() => {
				this.timeout = null;
			}, 500);
		} else if (this.proxy.calendar.isAssistent || this.proxy.calendar.isAdmin) {
			let obj: any = {};
			obj.startDate = event.cellData.startDate;
			obj.files = [];
			if (event.cellData.groups) {
				obj.userId = event.cellData.groups.ap_userId;
			}
			this.proxy.appointment.showPopup(obj).then(() => {
				this.initScheduler();
			});
		}
		event.cancel = true;
	}

	getAppointmentData(data: any) {
		const app = data.appointmentData;
		const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
		const selectedDate = new Date(new Date(app.startDate).setHours(0, 0, 0, 0));
		const notPastAppointment = currentDate <= selectedDate;

		this.showBeginConsultCondition = (app.ap_statusId === 2 || app.ap_statusId === 1) && notPastAppointment;
	}

	appointmentStatus(appointment: any, status: number, ev?: any) {
		this.preventDefaultPopup(ev);
		switch (status) {
			// reprogrameaza
			case 2:
				this.proxy.appointment.showPopup(appointment.ap_id, true);
				break;
			// confirma consultatia
			case 3:
				this.hideAppointmentTooltip();
				this.proxy.entity.save('Appointment', appointment.ap_id, { statusId: 3 });
				break;

			case 4: // incepe consultatia
			case 11: // modifica consultatia
				this.hideAppointmentTooltip();
				this.proxy.consultation.showPopup(appointment.ap_id, true);
				break;
			case 5: // finalizeaza consultatie
				this.hideAppointmentTooltip();
				this.proxy.consultation.finishConsultation(appointment.ap_id);
				break;

			case 10: // sterge consultatie
				this.hideAppointmentTooltip();
				this.proxy.consultation.remove(appointment.ap_id);
				break;
		}
	}

	finishConsultation(ev: any, data: any) {
		this.preventDefaultPopup(ev);
		this.hideAppointmentTooltip();
		this.proxy.consultation.finishConsultation(data.ap_id);
	}

	onSpecializationSelectionEv() {
		this.proxy.calendar.getDoctorsBySpec();
	}

	downloadAppointment(data: any, ev: any) {
		this.preventDefaultPopup(ev);
		this.proxy.calendar.downloadAppointment(data).then(
			() => { notify(this.proxy.ds.lg.format('FILES_DOWNLOADED', null), 'success', 2000); },
			() => { notify(this.proxy.ds.lg.format('DOWNLOAD_ERROR', null), 'error', 3000); });
	}

	// TODO: TBD
	// sendPatientSms(data: any, ev: any) {
	// 	this.preventDefaultPopup(ev);
	// 	this.proxy.entity.execute('NotifyAppointment', { appointmentId: data.ap_id }).then(() => {
	// 		notify('Mesaj trimis cu succes.', 'success', 3000);
	// 		this.proxy.entity.save('Appointment', data.ap_id, { isSent: true }).then(() => {
	// 			this.scheduler.instance.hideAppointmentTooltip();
	// 		}, error => this.proxy.ds.lg.showError(error));
	// 	}, error => this.proxy.ds.lg.showError(error));
	// }

	preventDefaultPopup(ev: any) {
		if (ev && ev.event) {
			// dx button
			ev.event.preventDefault();
			ev.event.stopPropagation();
		} else if (ev) {
			// eveniment de click
			ev.preventDefault();
			ev.stopPropagation();
		}
	}

	onViewChangeEv(event: any) {
		if (event.name === 'currentView') {
			this.proxy.calendar.currentView = this.viewMap[event.value.toLowerCase()] || event.value;
		}
	}

	private viewMap: { [key: string]: string } = {
		zi: 'day',
		luna: 'month'
	};
}





